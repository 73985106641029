// import { createIntl, createIntlCache } from '@formatjs/intl';

// Polyfills
// import '@formatjs/intl-getcanonicallocales/polyfill';

// import '@formatjs/intl-locale/polyfill';

// import '@formatjs/intl-numberformat/polyfill';
// import '@formatjs/intl-numberformat/locale-data/en'; // locale-data for en
// import '@formatjs/intl-numberformat/locale-data/fr'; // locale-data for fr
// import '@formatjs/intl-numberformat/locale-data/de'; // locale-data for de
// // //
// import '@formatjs/intl-datetimeformat/polyfill';
// import '@formatjs/intl-datetimeformat/locale-data/en'; // locale-data for en
// import '@formatjs/intl-datetimeformat/locale-data/fr'; // locale-data for fr
// import '@formatjs/intl-datetimeformat/locale-data/de'; // locale-data for de
// import '@formatjs/intl-datetimeformat/add-all-tz' // Add ALL tz data

// import '@formatjs/intl-pluralrules/polyfill';
// import '@formatjs/intl-pluralrules/locale-data/en'; // locale-data for en
// import '@formatjs/intl-pluralrules/locale-data/fr'; // locale-data for fr
// import '@formatjs/intl-pluralrules/locale-data/de'; // locale-data for de

// Locales data
// import enData from './localizationData/en.js';
// import frData from './localizationData/fr.js';
// import deData from './localizationData/de.js';

export const localeDataAssociations = {
    // 'fr': frData,
    // 'en': enData,
    // 'de': deData,
};

export const enabledLanguages = [
    'fr',
    'en',
    'de',
    'nl',
    'it',
    'es',
    // 'da',
    // 'at',
];

export const defaultLanguage = 'fr';

// // this object will have language-specific data added to it which will be placed in the state when that language is active
// // if localization data get to big, stop importing in all languages and switch to using API requests to load upon switching languages
export const localizationData = {};

// This is optional but highly recommended
// since it prevents memory leak
// const cache = createIntlCache();

// enabledLanguages.forEach(locale => {
//     // Create the `intl` object
//     localizationData[locale] = createIntl(
//         {
//             // Locale of the application
//             locale,
//             // Locale of the fallback defaultMessage
//             defaultLocale: 'en',
//             messages: localeDataAssociations[locale] || {},
//             onError: err => {
//                 if(err.code === 'MISSING_TRANSLATION') {
//                     console.warn('Missing translation', err.message);
//                     return;
//                 }
//                 if(err.code === 'MISSING_DATA') {
//                     console.warn('Missing data', err.message);
//                     return;
//                 }
//                 throw err;
//             }
//         },
//         cache
//     );
// });
